import inView from 'in-view';
window.$ = require('jquery');
window.jQuery = window.$;

if ( $(window).width() > 500 && document.querySelector('.d-img-one') ) {
var intViewportHeight = window.innerHeight;
console.log(intViewportHeight);

// document.querySelector('.d-img-one').style.opacity = 1;

inView.offset(intViewportHeight / 2 - 100);

//inView.threshold(1);

inView('.drop-one')
    .on('enter', el => {
        document.querySelector('.d-img-one').style.opacity = 1;
        document.querySelector('.d-img-two').style.opacity = 0;
        document.querySelector('.d-img-three').style.opacity = 0;
        document.querySelector('.d-img-four').style.opacity = 0;
        document.querySelector('.d-img-five').style.opacity = 0;
    });

    // inView('.drop-one')
    // .on('exit', el => {
    //     document.querySelector('.d-img-one').style.opacity = 1;
    // });

inView('.drop-two')
    .on('enter', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-two').style.opacity = 1;
        document.querySelector('.d-img-three').style.opacity = 0;
        document.querySelector('.d-img-four').style.opacity = 0;
        document.querySelector('.d-img-five').style.opacity = 0;
        console.log('two enter');
    });

    /*inView('.drop-two')
    .on('exit', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-two').style.opacity = 0;
        console.log('two exit');
    });*/

    inView('.drop-three')
    .on('enter', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-three').style.opacity = 1;
        document.querySelector('.d-img-four').style.opacity = 0;
        document.querySelector('.d-img-five').style.opacity = 0;
        console.log('three enter');
    });

    /*inView('.drop-three')
    .on('exit', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-three').style.opacity = 0;
        console.log('three exit');
    });*/

    inView('.drop-four')
    .on('enter', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-four').style.opacity = 1;
        document.querySelector('.d-img-five').style.opacity = 0;
        console.log('four enter');
    });

    /*inView('.drop-four')
    .on('exit', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-four').style.opacity = 0;
        console.log('four exit');
    });*/

    inView('.drop-five')
    .on('enter', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-five').style.opacity = 1;
        console.log('five enter');
    });

    /*inView('.drop-five')
    .on('exit', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-five').style.opacity = 0;
        console.log('five exit');
    });*/

    inView('.reg-footer')
    .on('enter', el => {
        //document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-one').style.opacity = 0;
        document.querySelector('.d-img-two').style.opacity = 0;
        document.querySelector('.d-img-three').style.opacity = 0;
        document.querySelector('.d-img-four').style.opacity = 0;
        document.querySelector('.d-img-five').style.opacity = 0;
        console.log('footer enter');
    });

}; 
