(function() {
    const btnUp = document.querySelector('.js-up');
    const btnDown = document.querySelector('.js-down');
    const sliderPart = document.querySelector('.slider-part');
    const totalAmount = document.getElementsByClassName('slider-text').length;
    const eachHeightStep = 100 / totalAmount;
    const currentHeight = eachHeightStep;
    const sliderProg = document.querySelector('.progress-line');
    const sliderProgBar = document.querySelector('.progress-bar');
    const bgNumber = document.querySelector('.bg-num');
    let currentNum = 1;
    if ( sliderPart ) {
        sliderPart.innerHTML = '0' + currentNum + '/0' + totalAmount;
    }
    if ( sliderProgBar ) {
        sliderProgBar.style.height = currentHeight + '%';
    }

    if ( btnDown && btnUp ) {
        btnDown.addEventListener('click', () => {
            const currentActive = document.querySelector('.slider-text.active');
            const nextDown = currentActive.nextElementSibling;

            if ( nextDown && nextDown.classList.contains('slider-text') ) {
                currentNum++;
                //bgNumber.innerHTML = currentNum;
                const newHeight = eachHeightStep * currentNum;
                sliderProgBar.style.height = newHeight + '%';
                sliderPart.innerHTML = '0' + currentNum + '/0' + totalAmount;
                currentActive.classList.remove('active');
                nextDown.classList.add('active');
        }
        });

        btnUp.addEventListener('click', () => {
            const currentActive = document.querySelector('.slider-text.active');
            const nextUp = currentActive.previousElementSibling;

            if ( nextUp && nextUp.classList.contains('slider-text') ) {
                currentNum--;
                //bgNumber.innerHTML = currentNum;
                const newHeight = eachHeightStep * currentNum;
                sliderProgBar.style.height = newHeight + '%';
                sliderPart.innerHTML = '0' + currentNum + '/0' + totalAmount;
                currentActive.classList.remove('active');
                nextUp.classList.add('active');
        }
        });
    }
}());