// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.

import './TitleTransform';
import './MenuAnimation';
import './VerticalSlider';
import './ProductsHandler';
import './TextScroll';
import './drop';
import './Waves';
import './FlickityText';
import './SmoothScroll';
import './EmployeeFiltering';
import './Iframe';
import './SocialShare';
import './ReportNav';
import './foldingToggle';
import './CasesSlider';

$(function () {
    $(document).scroll(function () {
      var $nav = $("#job-nav");
      var $header = $('#job-header');
      $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height() + $header.height() - 100);
    });
  });