import { SineWaves } from 'sine-waves';

if (document.getElementById("waves")) {

let textHeight = document.getElementById('title').getBoundingClientRect().height

let lineHeight = 0;

if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
  lineHeight = 600;
} else {
   lineHeight =  (textHeight > 1) ? textHeight + 40 : this.text.length * 72*1.1 + 190;
}
let canvas = document.getElementById('waves')
let context = canvas.getContext('2d');


    context.clearRect(0, 0, canvas.width, canvas.height);

    let waves = new SineWaves({
      // Canvas Element
      el: document.getElementById('waves'),

      // General speed of entire wave system
      speed: 8,

      // How many degress should we rotate all of the waves
      rotate: 190,

      // Ease function from left to right
      ease: 'Linear',

      // Specific how much the width of the canvas the waves should be
      // This can either be a number or a percent
      wavesWidth: '100%',

      // An array of wave options
      waves: [
        {
          timeModifier: 0.3,   // This is multiplied againse `speed`
          lineWidth: lineHeight / 1.5,      // Stroke width
          amplitude: 50,    // How tall is the wave
          wavelength: 200,   // How long is the wave
          segmentLength: 20, // How smooth should the line be
          strokeStyle: 'rgba(252, 80, 80, 1)', // red
          type: 'sine',       // Wave type
          easing: 'SineInOut'
        },
        {
          timeModifier: 1.2,
          lineWidth: lineHeight / 1.5,
          amplitude: 25,
          wavelength: 100,
          segmentLength: 20,
          strokeStyle: 'rgba(255, 158, 1, 1)' // yellow
        },
        {
          timeModifier: 2,
          lineWidth: lineHeight / 3,
          amplitude: 10,
          wavelength: 70,
          segmentLength: 20,
          strokeStyle: 'rgba(37, 37, 234, 1)', // Blue
        }
      ],

      // Perform any additional initializations here
      initialize: function() {
        const parent = document.getElementById('wrap')
        parent.style.height = lineHeight + 'px'
        parent.parentNode.style.height = lineHeight + 'px'
        parent.parentNode.style.position = 'relative';
      },

      // This function is called whenver the window is resized
      resizeEvent: function() {
      }
    });

  }