let btns = document.querySelectorAll('.js-read-more');

function toggleProducts(productID)
{
    let product = document.querySelector('.prod-' + productID);
    let productBox = document.querySelector('.js-product-box-' + productID);
    let toggleBtn = document.querySelector('.js-read-more-' + productID);
    let readmoreText = document.querySelector('.product-box__read-more-text-' + productID);

    if ( product.classList.contains('hidden') ) {
        product.classList.remove('hidden');
        productBox.classList.add('activated');
        toggleBtn.classList.add('rotate');
        readmoreText.innerHTML = "Close";
    } else {
        product.classList.add('hidden');
        productBox.classList.remove('activated');
        toggleBtn.classList.remove('rotate');
        readmoreText.innerHTML = "Read more";
    }
}

if (btns) {

    btns.forEach(function (btn) {

        var btnID = btn.dataset.id;

        btn.addEventListener("click", function(event) {
            event.preventDefault();

            toggleProducts(btnID);
        });

    });

};