import Flickity from 'flickity';

const caseSlider = new Flickity('.js-case-slider', {
    accessibility: true,
    resize: true,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: true,
    percentPosition: true,
    setGallerySize: true,
    loop: true,
    contain: true
  });