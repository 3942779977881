$(window).scroll(function () {
    // var scrollTop = $(window).scrollTop();
    var reportHeroHeight = $('.report-hero').outerHeight() - 80;
      
      if ($(window).scrollTop() >= reportHeroHeight ) {
        $('#report-header').addClass('scrolled');
        var themecolor =  $('.first-line').data('color');
        $(".first-line").css("background-color", themecolor);
        $(".second-line").css("background-color", themecolor);
      }
      else  {
        $(".first-line").css("background-color", '#ffffff');
        $(".second-line").css("background-color", '#ffffff');
        $('#report-header').removeClass('scrolled');
      }
});


$('.js-report-header-hamburger').click(function() {
    if ($('#report-header').hasClass('open')) {
    
        $('#report-header').removeClass('open');
    } else {
        $('#report-header').addClass('open');
    }
});