let twitterShare = document.querySelector('[data-js="twitter-share"]');

    if(twitterShare != null){
    twitterShare.onclick = function(e) {
    e.preventDefault();
    var twitterWindow = window.open('https://twitter.com/share?url=' + document.URL, 'twitter-popup', 'height=350,width=600');
    if(twitterWindow.focus) { twitterWindow.focus(); }
        return false;
    }
}
let facebookShare = document.querySelector('[data-js="facebook-share"]');

if(facebookShare != null){
    facebookShare.onclick = function(e) {
    e.preventDefault();
    var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + document.URL, 'facebook-popup', 'height=350,width=600');
    if(facebookWindow.focus) { facebookWindow.focus(); }
        return false;
    }
}