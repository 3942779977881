var desktopIframeContainer = $('#desktop-iframe-container');
var mobileIframeContainer = $('#mobile-iframe-container');
var desktopSrc = desktopIframeContainer.data('src');
var mobileSrc = mobileIframeContainer.data('src');

function showIframe() {
    if (window.matchMedia("(min-width: 650px)").matches) {
        $(desktopIframeContainer).append(`<iframe width="600" height="600" src="${desktopSrc}" frameborder="0" style="border:0" allowfullscreen></iframe>`);
    }
    else {
        $(desktopIframeContainer).remove();
    }
    if (window.matchMedia("(max-width: 650px)").matches) {
        $(mobileIframeContainer).append(`<iframe width="600" height="750" src="${mobileSrc}" frameborder="0" style="border:0" allowfullscreen></iframe>`);
    }
    else {
        $(mobileIframeContainer).remove();
    }
}

$(window).on('load', function() {
    showIframe();
});