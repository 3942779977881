import bodymovin from 'bodymovin';

if ( document.getElementById('waves-holder') ) {
	var animation = bodymovin.loadAnimation({
    	container: document.getElementById('waves-holder'),
    	renderer: 'svg',
    	loop: true,
    	autoplay: true,
    	path: '/wp-content/themes/trickleproject/img/wave-final.json'
  	});
}